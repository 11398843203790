import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import WebFromApplication from './WebFormApplication';
import { useTheme } from 'src/hooks';

export const ApplicationFormSection = () => {
  const { form } = useTheme();

  return (
    <Container fluid className="bg-shapes-black-mountain bg-gray-800 px-0 py-6">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Col xs={12} className="text-center">
              <h2 className="text-center text-white">{form.interested}</h2>
              <div className="font-weight-normal text-size-lg text-white" dangerouslySetInnerHTML={{ __html: form.interestedSubtitle }} />
            </Col>
          </Col>
        </Row>
      </Container>
      <Container fluid className="active animate_base animate_fadeInUp px-0">
        <WebFromApplication />
      </Container>
    </Container>
  );
};
