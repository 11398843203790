import axios from 'axios';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTheme } from 'src/hooks';

const initialValues = {
  name: '',
  email: '',
  linkedin: '',
  message: '',
  cv: '',
  cvFile: null,
  privacy_policy: false,
  webform_id: 'application',
};

export const useWebformApplication = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const t = useTheme();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // 1. Recaptcha
      const token = await executeRecaptcha();
      if (!token) throw new Error('Failed to get reCAPTCHA token');
      await axios.post('/api/validate-captcha', { token });

      // 2. Upload CV
      const uploadRes = await axios.post('/api/upload-cv', values.cvFile, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `file; filename="${values.cvFile.name}"`,
        },
      });

      const asset = uploadRes.data;
      if (!asset._id || asset._id.includes('da39a3ee')) throw new Error('Invalid file uploaded');

      // 3. Submit form
      await axios.post('/api/send-application', {
        ...values,
        cv: asset._id,
        cvUrl: asset.url,
        cvFilename: asset.originalFilename,
        message: [values.message, 'Submitted at', window.location.pathname].join(' - '),
      });

      // 4. Reset + Redirect
      await resetForm();
      await navigate(t.pages.thankYou.pathName, { state: { fromWebform: true } });
    } catch (err) {
      console.error('[Form submission failed]', err);
    }
  };

  const formik = useFormik({ initialValues, onSubmit: handleSubmit });

  const formElements = [
    { name: 'name', label: t.form.name, type: 'text', required: true },
    { name: 'email', label: t.form.email, type: 'email', required: true },
    { name: 'linkedin', label: t.form.linkedin, type: 'url', required: false },
    { name: 'message', label: t.form.message, type: 'text', required: true, as: 'textarea', rows: 5 },
  ];

  return {
    formElements,
    formik,
    isSuccess: false,
    isError: false,
    isLoading: formik.isSubmitting,
  };
};
