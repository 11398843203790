/* eslint-disable max-len */
import { Link } from 'gatsby';
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useWebformApplication } from './hooks';
import ElementAlert from 'src/components/ElementAlert/ElementAlert';
import { useTheme } from 'src/hooks';

function WebFromApplication() {
  const t = useTheme();
  const { isSuccess, isError, isLoading, formElements, formik } = useWebformApplication();

  return (
    <Container fluid className="px-0 py-6">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} xl={{ span: 6, offset: 3 }}>
            <Form onSubmit={formik.handleSubmit}>
              {formElements.map((e) => (
                <Form.Group key={e.name} controlId={e.name}>
                  <Form.Label>{e.label}</Form.Label>
                  <Form.Control {...e} placeholder={e.label} value={formik.values[e.name]} onChange={formik.handleChange} />
                </Form.Group>
              ))}
              <Form.Group>
                <Form.File
                  custom
                  required
                  id="nudbvheibvchwic"
                  name="cv"
                  type="file"
                  placeholder={t.form.cv}
                  label={formik.values.cvFile ? formik.values.cvFile.name : ''}
                  value={formik.values.cv}
                  data-browse={t.form.cv}
                  accept=".txt,.rtf,.pdf,.doc,.docx,.odt,.ppt,.pptx,.odp,.xls,.xlsx,.ods"
                  onChange={(event) => {
                    formik.setFieldValue('cvFile', event.target.files[0]);
                    formik.setFieldValue('cv', event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="privacy-policy">
                <Form.Check
                  custom
                  required
                  name="privacy_policy"
                  type="checkbox"
                  label={
                    <span className="text-size-sm text-white">
                      <span className="mr-1">{t.form.gdprConsent}</span>
                      <Link to={`/${t.navigation.gdpr.path}`} className="text-secondary font-weight-semi-bold">
                        {t.navigation.gdpr.label}
                      </Link>
                    </span>
                  }
                  checked={formik.values.privacy_policy}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <div className="d-flex justify-content-center mt-8">
                <Button variant="gradient-secondary" type="submit" disabled={isLoading}>
                  {t.form.submit}
                  {isLoading && <span> ...</span>}
                </Button>
              </div>
            </Form>
            <br />
            {isSuccess && (
              <ElementAlert
                variant="success"
                title={t.form.confirmation.successTitle}
                description={t.form.confirmation.successDescription}
                className="animate-up mt-6"
              />
            )}
            {isError && (
              <ElementAlert
                variant="danger"
                title={t.form.confirmation.errorTitle}
                description={t.form.confirmation.errorDescription}
                className="animate-up mt-6"
              />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WebFromApplication;
